// i18next-extract-mark-ns-start products-pisdyn-piston

import { PISDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductPisdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							PISDYN is used to calculate secondary motions of piston
							assembly by solving forces and moments of the
							piston/liner system. The tool enables users to
							understand how these elements interact with lubrication
							of component interfaces throughout the engine cycle.
						</p>
						<p>
							The lubrication model includes the effects of
							hydrodynamic and boundary lubrication, as well as the
							elasticity of the piston skirt, cylinder liner, piston
							pin and connecting rod. PISDYN also allows for a full
							hierarchy of solution levels, from rigid through
							compliant to fully dynamic components, and from dry
							through partially flooded to fully flooded oil-supply
							zones.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>
								Mass-conserving elastohydrodynamic (EHD) lubrication
								model
							</li>
							<li>Boundary lubrication model</li>
							<li>Average flow model (flow coefficients)</li>
							<li>
								Dry, partially and fully flooded oil-supply options
							</li>
							<li>
								Rigid, compliant and dynamic models of piston,
								liner, pin and connecting rod
							</li>
							<li>
								Predicting lubricated extend and cavitated regions
							</li>
							<li>
								Modelling lubricated and un-lubricated zones and
								cut-outs
							</li>
							<li>
								Bore distortion and bore gas pressure deformation
							</li>
							<li>
								Piston skirt thermal, inertial and gas pressure
								deformation
							</li>
							<li>Bearings profiles and deformations</li>
							<li>Piston crown lands, skirt and bore profiles</li>
							<li>Spatial varying viscosity</li>
							<li>Crown lands contact model</li>
							<li>Simplified top-ring model</li>
							<li>
								Animated results on piston skirt, cylinder liner,
								wrist-pin bearings and crown lands
							</li>
							<li>Complete cycle results plot</li>
							<li>Cylinder-block analysis</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{PISDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductPisdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-pisdyn-piston", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/pisdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;

